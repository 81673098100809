import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CommunityMobilization = () => {
  return (
    <Box>
      <DailyActivities
        title="Community Mobilization"
        items={menuSections.communityMobilization}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CommunityMobilization;

export { CommunityLeaderEventPlansConductionReports } from "./community-leader-s-event-plans-conduction-reports";
export { CommunityStakeholdersInvolvementAndParticipationAnecdotes } from "./community-stakeholders-involvement-and-participation-anecdotes";
export { HouseVisitsMadeToEachStudentMonthly } from "./house-visits-made-to-each-student-monthly";
export { IrregularRegularChildrenResumingAfterCheckInVisits } from "./irregular-regular-children-resuming-after-check-in-visits";
export { OneOnOnePhoneCallsWithParentsForAbsentChildren } from "./one-on-one-phone-calls-with-parents-for-100-absent-children";
export { ParentsRespondingPositivelyDuringHouseVisits } from "./parents-responding-positively-during-house-visits";
export { YouthLeadersEventPlansConductionReports } from "./youth-leaders-event-plans-conduction-reports";
