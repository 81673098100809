import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";
import { Breadcrumb } from "@refinedev/mui";
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import PageHeading from "../../components/pageHeading";
import { IconDate, IconDownArrow } from "../../icons";
import { API_URL, TOKEN_KEY } from "../../constants";

type IUser = {
  email: string;
};

export const NumberOfBooksIssuedToChildrenInTheLibrary: React.FC = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<any>(null);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  // Fetch batches
  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  // Fetch students based on selected batch
  const { data: dataStudents } = useList({
    resource: "students",
    pagination: { pageSize: 100 },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    try {
      const bookIssueData = {
        data: {
          Student: selectedStudent?.id,
          Batch: selectedBatch?.id,
          Date: dayjs(date).format("YYYY-MM-DD"),
          BookName: data.BookName,
          WriterName: data.WriterName,
        },
      };

      await axios.post(`${API_URL}/api/book-issues`, bookIssueData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      open?.({
        type: "success",
        message: "Book issued successfully",
      });

      // Reset form
      setSelectedBatch(null);
      setSelectedStudent(null);
      setDate(null);
      reset();
    } catch (error) {
      open?.({
        type: "error",
        message: "Failed to issue book",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));",
      }}
    >
      <Breadcrumb />
      <PageHeading heading="Books Issued To Children In The Library" />
      <Box
        component="form"
        sx={{ width: "100%", padding: "1rem" }}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
              required
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month", "day"]}
                  label="Issued On"
                  format="YYYY-MM-DD"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setValue("IssuedOn", dayjs(newValue).format("YYYY-MM-DD"));
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      error: !!(errors as any)?.IssuedOn,
                    },
                  }}
                  slots={{
                    openPickerIcon: IconDate,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                      setSelectedStudent(null); // Reset selected student when batch changes
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option?.Name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors?.Batch?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              {...register("BookName", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.BookName}
              helperText={(errors as any)?.BookName?.message}
              fullWidth
              label="Book Name"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Student} fullWidth>
              <Controller
                name="Student"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedStudent(value);
                    }}
                    options={dataStudents?.data || []}
                    getOptionLabel={(option) => option?.ChildName}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option?.ChildName}
                      </li>
                    )}
                    value={selectedStudent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        error={!!errors.Student?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors?.Student?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register("WriterName", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.WriterName}
              helperText={(errors as any)?.WriterName?.message}
              fullWidth
              label="Writer Name"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 180 }}
                disabled={
                  !date ||
                  !selectedBatch ||
                  !selectedStudent ||
                  !watch("BookName")?.trim() ||
                  !watch("WriterName")?.trim()
                }
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NumberOfBooksIssuedToChildrenInTheLibrary;
