import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const SMCProjectsForSchoolImprovementPerOneYear = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="SMC projects for school improvement (Per 1 year)" />
    </Box>
  );
};
