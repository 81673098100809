import { useDataGrid } from "@refinedev/mui";
import { useGetIdentity } from "@refinedev/core";

import MigrationTable from "../../components/migrationTable";

type IUser = {
  email: string;
};

export const MigrationListYour = () => {
  const { data: user } = useGetIdentity<IUser>();

  const { dataGridProps: dataStudents } = useDataGrid({
    resource: "migrations",
    meta: {
      populate: "*",
    },
    filters: {
      permanent: [
        {
          field: "CreatedBy.email",
          operator: "eq",
          value: user?.email,
        },
      ],
    },
  });

  return (
    <MigrationTable title="Migrations Created by You" data={dataStudents} />
  );
};
