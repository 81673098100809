import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CYRCLibrary = () => {
  return (
    <Box>

      <DailyActivities
        title="CYRC (Library)"
        items={menuSections.CYRCLibrary}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CYRCLibrary;

export { CYRCLibraryAverageAttendanceInLibraryClasses } from "./average-attendance-in-library-classes";
export { CYRCLibraryBooksIssuedToChildren } from "./books-issued-to-children";
export { CYRCLibraryChildrenAskingQuestionsDuringActivity } from "./children-asking-questions-during-activity";
export { CYRCLibraryChildrenAttendingLibraryClasses } from "./children-attending-library-classes-80";
export { CYRCLibraryChildrenParticipatingInGroupActivity } from "./children-participating-in-group-activity";
export { CYRCLibraryGroupReadingSessionsConducted } from "./group-reading-sessions-conducted";
export { CYRCLibraryReadAloudSessionsConducted } from "./read-aloud-sessions-conducted";
export { CYRCLibrarySilentReadingSessionsConducted } from "./silent-reading-sessions-conducted";
export { CYRCLibraryStorytellingSessionsConducted } from "./storytelling-sessions-conducted";
export { CYRCLibraryWritingClassesConducted } from "./writing-classes-conducted";
