import { useEffect, useState } from "react";
import { Create } from "@refinedev/mui";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import axios from "axios";

import PageHeading from "../../components/pageHeading";
import TextFieldShow from "../../components/textFieldShow";
import { IconDownArrow, IconDownArrow2 } from "../../icons";
import { API_URL, TOKEN_KEY } from "../../constants";

type IUser = {
  email: string;
  id: string;
};

export const MigrationCreate = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [partnerOrganization, setPartnerOrganization] = useState(null);
  const [externalOrganization, setExternalOrganization] = useState(null);
  const [migationType, setMigationType] = useState("");

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
    meta: {
      populate: ["Community"],
    },
  });

  const { data: dataCommunity } = useList({
    resource: "communities",
  });

  const { data: dataExternalOrg } = useList({
    resource: "external-orgs",
  });

  const { data: dataPartnerOrg } = useList({
    resource: "partner-orgs",
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const { data: dataStudent } = useList({
    resource: "students",
    pagination: {
      pageSize: 1,
    },
    filters: [
      {
        field: "id",
        operator: "eq",
        value: selectedStudent?.id,
      },
    ],
    meta: {
      populate: "*",
    },
  });

  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    setValue,
    resetField,
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Migration Record created successfully",
          type: "success",
        };
      },
      onMutationSuccess: ({
        data: {
          data: { id },
        },
      }) => {
        if (migationType === "Outside LIFI") {
          const sendMigrationMail = async () => {
            await axios.post(
              `${API_URL}/api/migration/partnerEmail`,
              {
                from: "LIFI <info@learninginitiativesforindia.org>",
                to: [partnerOrganization.Email],
                subject: `Migration Request for ${dataStudent?.data[0]?.ChildName}`,
                html: `<p>Hi ${partnerOrganization.Name},</p> <p>Here is the migration record of ${dataStudent?.data[0]?.ChildName}.</p>
                <div style="padding: 12px 20px; background: #f9f9f9;">
                  <h3>Student Details</h3>
                  <p>Child Name: ${dataStudent?.data[0]?.ChildName}</p>
                  <p>Date of Birth: ${dataStudent?.data[0]?.DateOfBirth}</p>
                  <p>Age: ${dataStudent?.data[0]?.Age}</p>
                  <p>Gender: ${dataStudent?.data[0]?.Gender}</p>
                  <p>Fathers Name: ${dataStudent?.data[0]?.Survey.FathersName}</p>
                  <p>Mothers Name: ${dataStudent?.data[0]?.Survey.MothersName}</p>
                  <p>Address: ${dataStudent?.data[0]?.Survey?.FullAddress}</p>
                  <p>Phone Number: ${dataStudent?.data[0]?.Survey?.PhoneNumber}</p>
                  <p>WhatsApp Number: ${dataStudent?.data[0]?.Survey?.WhatsappNumber}</p>
                  <p>Email ID: ${dataStudent?.data[0]?.Survey?.EmailId}</p>
                </div>
                <p>To accept the migration request, please click on the button below.</p>
                <a href="${API_URL}/api/migration/partnerAccept/${id}" target="_blank" style="p-x:20px;p-y:12px;line-height:100%;text-decoration:none;display:inline-block;max-width:100%;padding:12px 20px;border-radius:0.25rem;background-color:rgb(0,0,0);text-align:center;font-size:12px;font-weight:600;color:rgb(255,255,255);text-decoration-line:none"><span></span><span style="p-x:20px;p-y:12px;max-width:100%;display:inline-block;line-height:120%;text-decoration:none;text-transform:none;mso-padding-alt:0px;mso-text-raise:9px">Accept Migration</span><span></span></a>
                <p>Regards,</p> <p>learninginitiativesforindia.org</p>`,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          };
          sendMigrationMail();
        }
        if (migationType === "Other") {
          const sendMigrationMail = async () => {
            await axios.post(
              `${API_URL}/api/migration/externalEmail`,
              {
                from: "LIFI <info@learninginitiativesforindia.org>",
                to: [externalOrganization.Email],
                subject: `Migration Request for ${dataStudent?.data[0]?.ChildName}`,
                html: `<p>Hi ${externalOrganization.Name},</p> <p>Here is the migration record of ${dataStudent?.data[0]?.ChildName}.</p>
                <div style="padding: 12px 20px; background: #f9f9f9;">
                  <h3>Student Details</h3>
                  <p>Child Name: ${dataStudent?.data[0]?.ChildName}</p>
                  <p>Date of Birth: ${dataStudent?.data[0]?.DateOfBirth}</p>
                  <p>Age: ${dataStudent?.data[0]?.Age}</p>
                  <p>Gender: ${dataStudent?.data[0]?.Gender}</p>
                  <p>Fathers Name: ${dataStudent?.data[0]?.Survey.FathersName}</p>
                  <p>Mothers Name: ${dataStudent?.data[0]?.Survey.MothersName}</p>
                  <p>Address: ${dataStudent?.data[0]?.Survey?.FullAddress}</p>
                </div>
                <p>To accept the migration request, please click on the button below.</p>
                <a href="${API_URL}/api/migration/externalAccept/${id}" target="_blank" style="p-x:20px;p-y:12px;line-height:100%;text-decoration:none;display:inline-block;max-width:100%;padding:12px 20px;border-radius:0.25rem;background-color:rgb(0,0,0);text-align:center;font-size:12px;font-weight:600;color:rgb(255,255,255);text-decoration-line:none"><span></span><span style="p-x:20px;p-y:12px;max-width:100%;display:inline-block;line-height:120%;text-decoration:none;text-transform:none;mso-padding-alt:0px;mso-text-raise:9px">Accept Migration</span><span></span></a>
                <p>Regards,</p> <p>learninginitiativesforindia.org</p>`,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          };
          sendMigrationMail();
        }
        reset();
        setSelectedBatch(null);
        setSelectedStudent(null);
        setSelectedCommunity(null);
        setPartnerOrganization(null);
        setExternalOrganization(null);
        setMigationType(null);
      },
    },
  });

  useEffect(() => {
    resetField("Student");
  }, [selectedBatch]);

  const setStatus = (type: string) => {
    switch (type) {
      case "Inside LIFI":
        return "Created";
      case "Outside LIFI":
        return "Outside LIFI Created";
      case "Other":
        return "Others Created";
      default:
        return "Created";
    }
  };

  useEffect(() => {
    setValue("Title", dataStudent?.data[0]?.ChildName);
    setValue("SourceLocation", selectedBatch?.Community?.Name);
    setValue("Status", setStatus(migationType));
    setValue("CreatedBy", user?.id);
    setValue("MigrationType", migationType);
    if (migationType === "Inside LIFI") {
      setValue("MigrationLocation", selectedCommunity?.Name);
    } else if (migationType === "Outside LIFI") {
      setValue("PartnerOrganization", partnerOrganization?.id);
      setValue("MigrationLocation", partnerOrganization?.Location);
    } else if (migationType === "Others") {
      setValue("ExternalOrganisation", externalOrganization?.id);
      setValue("MigrationLocation", externalOrganization?.Location);
    }
  }, [
    selectedStudent,
    dataStudent,
    migationType,
    selectedCommunity,
    partnerOrganization,
    externalOrganization,
  ]);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinish) }}
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      resource="migration"
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Create Migration" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container columnSpacing={6} rowSpacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Student"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedStudent(value);
                    }}
                    options={dataStudents?.data || []}
                    getOptionLabel={(option) => option?.ChildName}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.ChildName}
                        </li>
                      );
                    }}
                    value={selectedStudent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Student?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          {dataStudent?.data[0] && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight={600}>
                  Student Details
                </Typography>
              </Grid>
              <TextFieldShow
                label="Child Name"
                value={dataStudent?.data[0]?.ChildName}
              />
              <TextFieldShow
                label="Date of Birth"
                value={dataStudent?.data[0]?.DateOfBirth || ""}
              />
              <TextFieldShow label="Age" value={dataStudent?.data[0]?.Age} />
              <TextFieldShow
                label="Gender"
                value={dataStudent?.data[0]?.Gender}
              />
              <TextFieldShow
                label="Fathers Name"
                value={dataStudent?.data[0]?.Survey.FathersName}
              />
              <TextFieldShow
                label="Mothers Name"
                value={dataStudent?.data[0]?.Survey.MothersName}
              />
              <TextFieldShow
                label="Address"
                value={dataStudent?.data[0]?.Survey?.FullAddress}
              />
              <TextFieldShow
                label="Batch"
                value={dataStudent?.data[0]?.Batch.Name}
              />
              <Grid
                item
                xs={12}
                sx={{
                  mt: 3,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Type of Migration
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("MigrationType", {
                    required: "This field is required",
                  })}
                  error={!!(errors as any)?.MigrationType}
                  helperText={(errors as any)?.MigrationType?.message}
                  name="MigrationType"
                  margin="normal"
                  fullWidth
                  select
                  label="MigrationType"
                  value={migationType}
                  required
                  onChange={(e) => setMigationType(e.target.value)}
                  SelectProps={{
                    IconComponent: IconDownArrow2,
                  }}
                >
                  <MenuItem value="Inside LIFI">Inside LIFI</MenuItem>
                  <MenuItem value="Outside LIFI">Outside LIFI</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid>
              {migationType === "Inside LIFI" && (
                <Grid item xs={12} md={6}>
                  <FormControl error={!!(errors as any)?.Community} fullWidth>
                    <Controller
                      name="Community"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            setSelectedCommunity(value);
                            field.onChange(value?.id);
                          }}
                          value={selectedCommunity}
                          options={dataCommunity?.data || []}
                          getOptionLabel={(option) => option?.Name}
                          popupIcon={<IconDownArrow />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={uuidv4()}>
                                {option?.Name}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Migration Community"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {(errors as any)?.Community?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {migationType === "Outside LIFI" && (
                <Grid item xs={12} md={6}>
                  <FormControl error={!!(errors as any)?.Community} fullWidth>
                    <Controller
                      name="PartnerOrganization"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            setPartnerOrganization(value);
                            field.onChange(value?.id);
                          }}
                          value={partnerOrganization}
                          options={dataPartnerOrg?.data || []}
                          getOptionLabel={(option) => option?.Name}
                          popupIcon={<IconDownArrow />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={uuidv4()}>
                                {option?.Name}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Partner Organization"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {(errors as any)?.Community?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {migationType === "Other" && (
                <Grid item xs={12} md={6}>
                  <FormControl error={!!(errors as any)?.Community} fullWidth>
                    <Controller
                      name="ExternalOrganization"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            setExternalOrganization(value);
                            field.onChange(value?.id);
                          }}
                          value={externalOrganization}
                          options={dataExternalOrg?.data || []}
                          getOptionLabel={(option) => option?.Name}
                          popupIcon={<IconDownArrow />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={uuidv4()}>
                                {option?.Name}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select External Organization"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {(errors as any)?.Community?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Create>
  );
};
