import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const SuggestionsForCYRCImprovementByCommittee = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Suggestions for CYRC improvement by committee" />
    </Box>
  );
};
