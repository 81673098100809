import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CYRCManagementCommittee = () => {
  return (
    <Box>

      <DailyActivities
        title="CYRC (Management Committee)"
        items={menuSections.CYRCManagementCommittee}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CYRCManagementCommittee;

export { ChildrenRetainedInCYRCs } from "./children-retained-in-cyrcs";
export { CYRCManageCommitteeMeetingsConducted } from "./committee-meetings-conducted";
export { CYRCManageEmpowermentTrainingsConducted } from "./empowerment-trainings-conducted";
export { IncreaseInCYRCEnrollments } from "./increase-in-cyrc-enrollments";
export { CYRCManageParentalCommunityInvolvementInCYRCs } from "./parental-community-involvement-in-cyrcs";
export { ParentsAttendingPTMsAndCYRCEvents } from "./parents-attending-ptms-and-cyrc-events";
export { CYRCManageParentsParticipatingInCommitteeMeetings } from "./parents-participating-in-committee-meetings";
export { ProjectsByCommitteeForCYRCimprovement } from "./projects-by-committee-for-cyrc-improvement";
export { SuggestionsForCYRCImprovementByCommittee } from "./suggestions-for-cyrc-improvement-by-committee";
