import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const VideosPhotosSharedByFellowsInWhatsApp = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Videos / photos shared by fellows in WhatsApp" />
    </Box>
  );
};
