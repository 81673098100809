import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CommunityLeaderAspectTrainingAspect = () => {
  return (
    <Box>
      <DailyActivities
        title="Community Leader Aspect / Training Aspect"
        items={menuSections.communityLeaderAspect}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CommunityLeaderAspectTrainingAspect;

export { ClassroomObservationsByLeaders } from "./classroom-observations-by-leaders";
export { CommunityLeaderVisitingFellowFourTimesMonth } from "./community-leader-visiting-fellow-4x-month";
export { FellowTeacherMeetingsToTrackAttendance } from "./fellow-teacher-meetings-to-track-attendance";
export { FellowsAttendingWeeklyTraining } from "./fellows-attending-weekly-training";
export { FellowsFillingGoogleFormPostTraining } from "./fellows-filling-google-form-post-training";
export { FellowsImplementingSkillsFromOnlineTraining } from "./fellows-implementing-skills-from-online-training";
export { NoOfFeedbackSessionsPlannedByLeaders } from "./no-of-feedback-sessions-planned-by-leaders";
export { NumberOfKURPICDCallsByLeaders } from "./number-of-kurpicd-calls-by-leaders";
export { ReportsCreatedByLeadersForMEEvents } from "./reports-created-by-leaders-for-m-e-events";
export { TrainingsForFellowsInTheMonth } from "./trainings-for-fellows-in-the-month";
export { CommunityVideosPhotosSharedByFellowsInWhatsApp } from "./videos-photos-shared-by-fellows-in-whatsapp";
export { VisitsToGovtOfficesSchoolsByMeenu } from "./visits-to-govt-offices-schools-by-meenu";
