import React from "react";
import { List } from "@refinedev/mui";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

import PageHeading from "../pageHeading";

const MigrationTable = ({ title, data }) => {
  const getChipColor = (status: string) => {
    switch (status) {
      case "Accepted":
        return "success";
      case "Created":
        return "info";
      case "Cancelled":
        return "primary";
      case "In Progress":
        return "warning";
      case "Outside LIFI Created":
        return "info";
      case "Outside LIFI Accepted":
        return "success";
      case "Others Created":
        return "info";
      case "Others Accepted":
        return "success";
      default:
        return "default";
    }
  };

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        width: 40,
      },
      {
        field: "ChildName",
        flex: 1,
        headerName: "Child Name",
        minWidth: 100,
        renderCell: function render({ row }) {
          return <>{row.Student.ChildName}</>;
        },
      },
      {
        field: "SourceLocation",
        flex: 1,
        headerName: "Source Location",
        minWidth: 100,
      },
      {
        field: "MigrationLocation",
        flex: 1,
        headerName: "Migration Location",
        minWidth: 100,
      },
      {
        field: "Status",
        flex: 1,
        headerName: "Status",
        width: 150,
        renderCell: function render({ row }) {
          return (
            <Chip
              label={row.Status}
              variant="outlined"
              color={getChipColor(row.Status)}
            />
          );
        },
      },
      {
        field: "Created By",
        flex: 1,
        headerName: "Created By",
        minWidth: 100,
        renderCell: function render({ row }) {
          return <>{row?.CreatedBy?.username}</>;
        },
      },
      {
        field: "AcceptedBy",
        flex: 1,
        headerName: "Accepted By",
        minWidth: 100,
        renderCell: function render({ row }) {
          return <>{row?.AcceptedBy?.username}</>;
        },
      },
      {
        field: "Action",
        flex: 1,
        headerName: "Action",
        minWidth: 100,
        renderCell: function render({ row }) {
          return row.Status === "Created" ? (
            <Button
              variant="contained"
              href={`/migration/edit/${row.id}`}
              size="small"
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              href={`/migration/edit/${row.id}`}
              size="small"
            >
              View
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      headerProps={{
        sx: {
          display: "none",
        },
      }}
    >
      <PageHeading heading={title} />
      <DataGrid
        {...data}
        columns={columns}
        autoHeight
        density="comfortable"
        sx={{ cursor: "pointer" }}
      />
    </List>
  );
};

export default MigrationTable;
