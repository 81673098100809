import { useState } from "react";
import PageHeading from "../../components/pageHeading";
import { useForm } from "@refinedev/react-hook-form";
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  Button,
} from "@mui/material";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconDate, IconDownArrow } from "../../icons";
import { v4 as uuidv4 } from "uuid";
import { Breadcrumb } from "@refinedev/mui";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "../../constants";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";

type IUser = {
  email: string;
  id: string;
};

export const SchoolStaffInvolvementInLibraryActivities = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [date, setDate] = useState(null);
  const { open } = useNotification();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  // Fetch batches
  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    try {
      const metricsData = {
        data: {
          Batch: selectedBatch?.id,
          Date: dayjs(date).format("YYYY-MM-DD"),
          InvlovedStaffName: data.InvolvedStaff,
          Description: data.Description,
          YouthLeader: user?.id,
        },
      };

      await axios.post(`${API_URL}/api/anecdots`, metricsData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      open?.({
        type: "success",
        message: "Saved successfully",
      });

      // Reset form
      setSelectedBatch(null);
      setDate(null);
      reset();
    } catch (error) {
      open?.({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));",
        }}
      >
        <Breadcrumb />
        <PageHeading heading="School Staff Involvement In Library Activities" />
        <Box
          component="form"
          sx={{ width: "100%", padding: "1rem" }}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{
                  ".MuiTextField-root": {
                    minWidth: "100% !important",
                  },
                  ".MuiStack-root": {
                    marginTop: "-8px !important",
                  },
                }}
                required
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    label="Conducted On"
                    format="YYYY-MM-DD"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                      setValue(
                        "ConductedOn",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!(errors as any)?.ConductedOn,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl error={!!(errors as any)?.Batch} fullWidth>
                <Controller
                  name="Batch"
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        setSelectedBatch(value);
                        field.onChange(value?.id);
                      }}
                      value={selectedBatch}
                      options={dataBatch?.data || []}
                      getOptionLabel={(option) => option?.Name}
                      popupIcon={<IconDownArrow />}
                      renderOption={(props, option) => (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Student Batch"
                          variant="outlined"
                          error={!!errors.Batch?.message}
                          required
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText>
                  {errors?.Batch?.message as string}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                sx={{ margin: 0 }}
                {...register("InvolvedStaff", {
                  required: "This field is required",
                  valueAsNumber: false,
                })}
                error={!!(errors as any)?.InvolvedStaff}
                helperText={(errors as any)?.InvolvedStaff?.message}
                margin="normal"
                fullWidth
                label="Involved Staff"
                required
                name="InvolvedStaff"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ margin: 0 }}
                {...register("Description", {
                  required: "This field is required",
                })}
                error={!!(errors as any)?.Description}
                helperText={(errors as any)?.Description?.message}
                margin="normal"
                fullWidth
                label="Description (optional)"
                name="Description"
                multiline
                minRows={5}
                maxRows={5}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: 180 }}
                  disabled={
                    !date || !selectedBatch || !watch("InvolvedStaff")?.trim()
                  }
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
