import { useDataGrid } from "@refinedev/mui";

import MigrationTable from "../../components/migrationTable";

export const MigrationListAll = () => {
  const { dataGridProps: dataStudents } = useDataGrid({
    resource: "migrations",
    meta: {
      populate: "*",
    },
  });

  return <MigrationTable title="All Migrations" data={dataStudents} />;
};
