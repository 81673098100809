import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const SchoolSMC = () => {
  return (
    <Box>

      <DailyActivities
        title="School SMC"
        items={menuSections.schoolSMC}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default SchoolSMC;

export { IntroductoryCallsWithSMCPlanByFellows } from "./introductory-calls-with-smc-plan-by-fellows";
export { AnecdotesOfParentalCommunityInvolvementInSchool } from "./anecdotes-of-parental-community-involvement-in-school";
export { IncreaseInSchoolEnrollmentsAttendance } from "./increase-in-school-enrollments-attendance";
export { NumberOfEmpowermentTrainingsConducted } from "./number-of-empowerment-trainings-conducted";
export { NumberOfSMCMeetingsConducted } from "./number-of-smc-meetings-conducted";
export { ParentsAttendingParentTeacherMeetings } from "./parents-attending-parent-teacher-meetings";
export { ParentsAttendingSMCMeetings } from "./parents-attending-smc-meetings";
export { SMCMemberParticipationInIntroductoryCall } from "./smc-member-participation-in-introductory-call";
export { SMCProjectsForSchoolImprovementPerOneYear } from "./smc-projects-for-school-improvement-per-1-year";
export { SuggestionsForSchoolImprovementBySMCMembers } from "./suggestions-for-school-improvement-by-smc-members";
export { VideosPhotosSharedByFellowsInWhatsApp } from "./videos-photos-shared-by-fellows-in-whatsapp";
