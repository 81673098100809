import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const CYRCManageParentsParticipatingInCommitteeMeetings = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Parents participating in committee meetings (%)" />
    </Box>
  );
};
