import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const MaatiPathshalaFLN = () => {
  return (
    <Box>

      <DailyActivities
        title="Maati Pathshala (FLN)"
        items={menuSections.maatiPathshalaFLN}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default MaatiPathshalaFLN;

export { MaatiChildrenAskingQuestionsDuringActivity } from "./children-asking-questions-during-activity";
export { ChildrenCompletingDailyWorksheets } from "./children-completing-daily-worksheets";
export { ChildrenParticipatingInGroupActivities } from "./children-participating-in-group-activities";
export { MaatiSELSessionsConductedPerBatch } from "./sel-sessions-conducted-per-batch";
export { StudentsScoringAboveInAssessments } from "./students-scoring-above-70-in-assessments";
export { StudentsTestedForAssessmentsBaselineMidlineEndline } from "./students-tested-for-assessments-baseline-midline-endline";
