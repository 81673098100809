import { useEffect, useState } from "react";
import { useDataGrid } from "@refinedev/mui";
import axios from "axios";

import MigrationTable from "../../components/migrationTable";
import { apiURL } from "../../authProvider";
import { TOKEN_KEY } from "../../constants";

export const MigrationListRelevent = () => {
  const [community, setCommunity] = useState(null);
  const token = localStorage.getItem(TOKEN_KEY);

  const getUserData = async () => {
    const { data } = await axios.get(`${apiURL}/users/me?populate=Community`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCommunity(data?.Community?.Name);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const { dataGridProps: dataStudents } = useDataGrid({
    resource: "migrations",
    meta: {
      populate: "*",
    },
    filters: {
      permanent: [
        {
          field: "Status",
          operator: "eq",
          value: "Created",
        },
        {
          field: "MigrationLocation",
          operator: "eq",
          value: community,
        },
      ],
    },
  });

  return (
    <MigrationTable title="Open and Relevent Migrations" data={dataStudents} />
  );
};
