import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CYRCFLN = () => {
  return (
    <Box>

      <DailyActivities
        title="CYRC (FLN)"
        items={menuSections.cyrcFLN}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CYRCFLN;

export { ChildrenCompletingEverydayWorksheets } from "./children-completing-everyday-worksheets";
export { SELSessionsConductedPerBatch } from "./sel-sessions-conducted-per-batch";
export { CYRCFLNChildrenAskingQuestionsDuringActivity } from "./children-asking-questions-during-activity";
export { ChildrenParticipatingInGroupActivity } from "./children-participating-in-group-activity";
export { StudentsTestedForAssessments } from "./students-tested-for-assessments";
export { StudentsScoringInAssessments } from "./students-scoring-70-in-assessments";
