import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const FellowsFillingGoogleFormPostTraining = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Fellows filling Google form post-training (%)" />
    </Box>
  );
};
