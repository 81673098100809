const getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return "http://139.59.24.180:1340";
    // return "https://lifi-api.tunicalabsmedia.com";
  } else if (process.env.NODE_ENV === "test") {
    return "http://139.59.24.180:1340";
  }
  return "http://localhost:1340";
};

export const API_URL = getApiUrl();

export const TOKEN_KEY = "lifi-jwt-token";

// Colors
export const Pie3Colors = ["#00813e", "#d39700", "#c41a00"];
export const Pie2Colors = ["#00813e", "#c41a00"];

// Feature Flags
export const MIGRATION_FEATURE = true;
