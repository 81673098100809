import { Grid, TextField, Typography } from "@mui/material";

const TextFieldShow = ({ label, value }) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="body1" fontWeight="bold">
        {label}
      </Typography>
      <TextField
        value={value || ""}
        sx={{
          width: "100%",
          mt: 0.5,
          "& .MuiInputBase-input": {
            WebkitTextFillColor: "#000 !important",
          },
        }}
        variant="outlined"
        disabled
      />
    </Grid>
  );
};

export default TextFieldShow;
