import {
  IconViewLesson,
  IconAddAttendance,
  IconStudentAssesment,
  IconStudentActivity,
  IconStudentHealth,
  IconParentMeet,
  IconAddStudent,
  IconStudentAttendance,
  IconAddSurvey,
} from "../icons";

// Define a type for menu items to improve type safety
interface MenuItem {
  slug: string;
  heading: string;
  icon: React.ReactNode;
}

export const menuSections: Record<string, MenuItem[]> = {
  mAndE: [
    {
      slug: "/school-library",
      heading: "School Library",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/school-smc",
      heading: "School SMC",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/community-leader-aspect-training-aspect",
      heading: "Community Leader Aspect / Training Aspect",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-overall",
      heading: "CYRC (Overall)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-fln",
      heading: "CYRC (FLN)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-library",
      heading: "CYRC (Library)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-stem-computers",
      heading: "CYRC (STEM + Computers)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-management-committee",
      heading: "CYRC (Management Committee)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/community-mobilization",
      heading: "Community Mobilization",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/maati-pathshala-fln",
      heading: "Maati Pathshala (FLN)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/maati-pathshala-library",
      heading: "Maati Pathshala (Library)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/maati-pathshala-community-mobilization",
      heading: "Maati Pathshala (Community Mobilization)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/student-advisory-board",
      heading: "Student Advisory Board",
      icon: <IconAddAttendance />,
    },
  ],
  dailyActivities: [
    {
      slug: "/student-attendance",
      heading: "Enter Attendance",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/lessons",
      heading: "View Lesson Plans",
      icon: <IconViewLesson />,
    },
    {
      slug: "/student-assessment",
      heading: "Enter Assessment",
      icon: <IconStudentAssesment />,
    },
    {
      slug: "/student-activity",
      heading: "Student Activity",
      icon: <IconStudentActivity />,
    },
  ],
  weeklyActivities: [
    {
      slug: "/student-health",
      heading: "Student Health",
      icon: <IconStudentHealth />,
    },
    {
      slug: "/parent-meet",
      heading: "Parent Meet",
      icon: <IconParentMeet />,
    },
    {
      slug: "/students/add-student",
      heading: "Add Student",
      icon: <IconAddStudent />,
    },
  ],
  reports: [
    {
      slug: "/student-attendance/report",
      heading: "Attendance",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/student-assessment/report",
      heading: "Assessment",
      icon: <IconAddSurvey />,
    },
  ],
  schoolLibrary: [
    {
      slug: "/school-library/class-conducted",
      heading: "Number of Class Conducted",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-library/engagement-metrics-for-children",
      heading: "Engagement Metrics for Children (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-library/number-of-books-issued-to-children-in-the-library",
      heading: "Number of books issued to children in the library",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-library/videos-photos-shared-by-fellows-in-whatsapp",
      heading: "Videos/photos shared by fellows in WhatsApp.",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-library/school-staff-involvement-in-library-activities",
      heading: "School staff involvement in Library activities.",
      icon: <IconStudentActivity />,
    },
  ],
  schoolSMC: [
    {
      slug: "/school-smc/introductory-calls-with-smc-plan-by-fellows",
      heading: "Introductory calls with SMC plan by fellows",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/smc-member-participation-in-introductory-call",
      heading: "SMC member participation in introductory call",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/number-of-smc-meetings-conducted",
      heading: "Number of SMC meetings conducted",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/number-of-empowerment-trainings-conducted",
      heading: "Number of empowerment trainings conducted",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/suggestions-for-school-improvement-by-smc-members",
      heading: "Suggestions for school improvement by SMC members",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/smc-projects-for-school-improvement-per-1-year",
      heading: "SMC projects for school improvement (Per 1 year)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/school-smc/parents-attending-parent-teacher-meetings",
      heading: "Parents attending Parent-Teacher Meetings (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-smc/parents-attending-smc-meetings",
      heading: "Parents attending SMC meetings (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-smc/increase-in-school-enrollments-attendance",
      heading: "Increase in school enrollments / attendance (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-smc/videos-photos-shared-by-fellows-in-whatsapp",
      heading: "Videos / photos shared by fellows in WhatsApp",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/school-smc/anecdotes-of-parental-community-involvement-in-school",
      heading: "Anecdotes of parental / community involvement in school",
      icon: <IconStudentActivity />,
    },
  ],
  communityLeaderAspect: [
    {
      slug: "/community-leader-aspect-training-aspect/number-of-kurpicd-calls-by-leaders",
      heading: "Number of KURPICD calls by leaders",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/classroom-observations-by-leaders",
      heading: "Classroom observations by leaders",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/visits-to-govt-offices-schools-by-meenu",
      heading: "Visits to govt. offices/schools by Meenu",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/fellows-filling-google-form-post-training",
      heading: "Fellows filling Google form post-training (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/trainings-for-fellows-in-the-month",
      heading: "Trainings for fellows in the month",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/fellows-attending-weekly-training",
      heading: "Fellows attending weekly training (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/fellow-teacher-meetings-to-track-attendance",
      heading: "Fellow-teacher meetings to track attendance",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/community-leader-visiting-fellow-4x-month",
      heading: "Community leader visiting fellow: 4x/month",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/fellows-implementing-skills-from-online-training",
      heading: "Fellows implementing skills from online training (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/no-of-feedback-sessions-planned-by-leaders",
      heading: "No. of Feedback sessions planned by leaders",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/videos-photos-shared-by-fellows-in-whatsapp",
      heading: "Videos/photos shared by fellows in WhatsApp",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-leader-aspect-training-aspect/reports-created-by-leaders-for-m-e-events",
      heading: "Reports created by leaders for M&E / events",
      icon: <IconStudentActivity />,
    },
  ],
  cyrcOverall: [
    {
      slug: "/cyrc-overall/activity-based-classes-conducted-in-cyrc",
      heading: "Activity-based classes conducted in CYRC",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-overall/average-attendance-in-learning-center-school",
      heading: "Average attendance in learning center / school (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-overall/children-attending-classes-80",
      heading: "Children attending classes >=80% (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-overall/videos-photos-shared-by-fellows-in-whatsapp",
      heading: "Videos / Photos shared by fellows in WhatsApp",
      icon: <IconStudentActivity />,
    },
  ],
  cyrcFLN: [
    {
      slug: "/cyrc-fln/children-completing-everyday-worksheets",
      heading: "Children completing everyday worksheets (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-fln/sel-sessions-conducted-per-batch",
      heading: "SEL sessions conducted per batch",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/cyrc-fln/children-asking-questions-during-activity",
      heading: "Children asking questions during activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-fln/children-participating-in-group-activity",
      heading: "Children participating in group activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-fln/students-tested-for-assessments",
      heading: "Students tested for assessments (%)",
      icon: <IconStudentAssesment />,
    },
    {
      slug: "/cyrc-fln/students-scoring-70-in-assessments",
      heading: "Students scoring >70% in assessments (%)",
      icon: <IconStudentAssesment />,
    },
  ],
  CYRCLibrary: [
    {
      slug: "/cyrc-library/storytelling-sessions-conducted",
      heading: "Storytelling sessions conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/read-aloud-sessions-conducted",
      heading: "Read-aloud sessions conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/group-reading-sessions-conducted",
      heading: "Group reading sessions conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/silent-reading-sessions-conducted",
      heading: "Silent reading sessions conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/writing-classes-conducted",
      heading: "Writing classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/average-attendance-in-library-classes",
      heading: "Average attendance in library classes (%)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-library/children-attending-library-classes-80",
      heading: "Children attending library classes >=80% (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-library/children-asking-questions-during-activity",
      heading: "Children asking questions during activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/children-participating-in-group-activity",
      heading: "Children participating in group activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-library/books-issued-to-children",
      heading: "Books issued to children",
      icon: <IconStudentActivity />,
    },
  ],
  CYRCSTEMComputers: [
    {
      slug: "/cyrc-stem-computers/stem-classes-planned",
      heading: "STEM classes planned",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-stem-computers/stem-classes-executed",
      heading: "STEM classes executed",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-stem-computers/children-enrolled-in-cyrcs",
      heading: "Children enrolled in CYRCs",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-stem-computers/children-graduated-grade-5-to-cyrc",
      heading: "Children graduated Grade 5 to CYRC",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-stem-computers/training-sessions-conducted",
      heading: "Training sessions conducted",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/cyrc-stem-computers/students-tested-in-baseline-midline-endline",
      heading: "Students tested in baseline / midline / endline (%)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-stem-computers/children-asking-questions-during-activity",
      heading: "Children asking questions during activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-stem-computers/children-participating-in-group-activity",
      heading: "Children participating in group activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-stem-computers/scratch-classes-projects-undertaken",
      heading: "Scratch classes / projects undertaken (%)",
      icon: <IconStudentActivity />,
    },
  ],
  CYRCManagementCommittee: [
    {
      slug: "/cyrc-management-committee/committee-meetings-conducted",
      heading: "Committee meetings conducted",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/cyrc-management-committee/empowerment-trainings-conducted",
      heading: "Empowerment trainings conducted",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/cyrc-management-committee/suggestions-for-cyrc-improvement-by-committee",
      heading: "Suggestions for CYRC improvement by committee",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-management-committee/projects-by-committee-for-cyrc-improvement",
      heading: "Projects by committee for CYRC improvement",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/cyrc-management-committee/parents-attending-ptms-and-cyrc-events",
      heading: "Parents attending PTMs and CYRC events (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-management-committee/parents-participating-in-committee-meetings",
      heading: "Parents participating in committee meetings (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-management-committee/increase-in-cyrc-enrollments",
      heading: "Increase in CYRC enrollments (%)",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/cyrc-management-committee/children-retained-in-cyrcs",
      heading: "Children retained in CYRCs (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/cyrc-management-committee/parental-community-involvement-in-cyrcs",
      heading: "Parental / community involvement in CYRCs",
      icon: <IconStudentActivity />,
    },
  ],
  communityMobilization: [
    {
      slug: "/community-mobilization/house-visits-made-to-each-student-monthly",
      heading: "House visits made to each student monthly",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-mobilization/irregular-regular-children-resuming-after-check-in-visits",
      heading:
        "Irregular & regular children resuming after check-in visits (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/community-mobilization/one-on-one-phone-calls-with-parents-for-100-absent-children",
      heading: "One-on-one phone calls with parents for 100% absent children",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/community-mobilization/parents-responding-positively-during-house-visits",
      heading: "Parents responding positively during house visits (%)",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/community-mobilization/youth-leaders-event-plans-conduction-reports",
      heading: "Youth leaders' event plans, conduction, reports",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-mobilization/community-leader-s-event-plans-conduction-reports",
      heading: "Community leader's event plans, conduction, reports",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/community-mobilization/community-stakeholders-involvement-and-participation-anecdotes",
      heading:
        "Community stakeholders' involvement and participation anecdotes",
      icon: <IconStudentActivity />,
    },
  ],
  maatiPathshalaFLN: [
    {
      slug: "/maati-pathshala-fln/children-completing-daily-worksheets",
      heading: "Children completing daily worksheets (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-fln/sel-sessions-conducted-per-batch",
      heading: "SEL sessions conducted per batch",
      icon: <IconAddSurvey />,
    },
    {
      slug: "/maati-pathshala-fln/children-asking-questions-during-activity",
      heading: "Children asking questions during activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-fln/children-participating-in-group-activities",
      heading: "Children participating in group activities (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-fln/students-tested-for-assessments-baseline-midline-endline",
      heading:
        "Students tested for assessments (baseline / midline / endline) (%)",
      icon: <IconStudentAssesment />,
    },
    {
      slug: "/maati-pathshala-fln/students-scoring-above-70-in-assessments",
      heading: "Students scoring above 70% in assessments (%)",
      icon: <IconStudentAssesment />,
    },
  ],
  maatiPathshalaLibrary: [
    {
      slug: "/maati-pathshala-library/storytelling-classes-conducted",
      heading: "Storytelling classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/read-aloud-classes-conducted",
      heading: "Read aloud classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/group-reading-classes-conducted",
      heading: "Group reading classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/silent-reading-classes-conducted",
      heading: "Silent reading classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/writing-classes-conducted",
      heading: "Writing classes conducted",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/average-attendance-in-library-classes",
      heading: "Average attendance in library classes (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/maati-pathshala-library/children-attending-classes-80",
      heading: "Children attending classes ≥80% (%)",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/maati-pathshala-library/children-asking-questions-during-activity",
      heading: "Children asking questions during activity (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/children-participating-in-group-activities",
      heading: "Children participating in group activities (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-library/books-issued-to-children-in-library",
      heading: "Books issued to children in library",
      icon: <IconStudentActivity />,
    },
  ],
  maatiPathshalaCommunityMobilization: [
    {
      slug: "/maati-pathshala-community-mobilization/house-visits-made-to-students-weekly",
      heading: "House visits made to students weekly",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/irregular-regular-children-resuming-after-visits",
      heading: "Irregular & regular children resuming after visits (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/one-on-one-phone-calls-with-absent-students-parents",
      heading: "One-on-one phone calls with absent students' parents",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/parents-responding-positively-to-ytls-during-visits",
      heading: "Parents responding positively to YTLs during visits (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/event-plans-conduction-and-reports-by-ytls",
      heading: "Event plans, conduction, and reports by YTLs",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/event-plans-conduction-and-reports-by-cl",
      heading: "Event plans, conduction, and reports by CL",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/involvement-of-community-stakeholders",
      heading: "Involvement of community stakeholders",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/visits-by-youth-community-leaders-at-source-villages",
      heading: "Visits by Youth/Community Leaders at source villages",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/children-visited-at-the-source-villages",
      heading: "Children visited at the source villages (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/children-mapped-in-schools-at-the-source",
      heading: "Children mapped in schools at the source (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/children-retained-in-the-next-session",
      heading: "Children retained in the next session (%)",
      icon: <IconStudentActivity />,
    },
    {
      slug: "/maati-pathshala-community-mobilization/children-tracked-for-continued-education",
      heading: "Children tracked for continued education (%)",
      icon: <IconStudentActivity />,
    },
  ],
};
