import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import PageHeading from "../../components/pageHeading";
import { IconDate, IconDownArrow, IconDownArrow2 } from "../../icons";
import { API_URL, TOKEN_KEY } from "../../constants";
import blurTarget from "../../utils/blurTarget";
import { Breadcrumb } from "@refinedev/mui";

type IUser = {
  email: string;
};

const engagementTypes = [
  {
    id: 1,
    value: "PercentageDuringActivity",
    label: "Percentage of children asking question during activity",
  },
  {
    id: 2,
    value: "PercentageGroupActivity",
    label: "Percentage of children participate in group activity ",
  },
];

export const EngagementMetricsForChildren: React.FC = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<any>(null);
  const [engagementType, setEngagementType] = useState("");
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  // Fetch batches
  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    try {
      const metricsData = {
        data: {
          Batch: selectedBatch?.id,
          ConductedOn: dayjs(date).format("YYYY-MM-DD"),
          EngagementType: engagementType,
          EngagementPercent: data.EngagementPercentage,
        },
      };

      await axios.post(`${API_URL}/api/engagement-metrics`, metricsData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      open?.({
        type: "success",
        message: "Engagement metrics saved successfully",
      });

      // Reset form
      setSelectedBatch(null);
      setDate(null);
      setEngagementType("");
      reset();
    } catch (error) {
      open?.({
        type: "error",
        message: "Failed to save engagement metrics",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));",
      }}
    >
      <Breadcrumb />
      <PageHeading heading="Engagement Metrics for Children (%)" />
      <Box
        component="form"
        sx={{ width: "100%", padding: "1rem" }}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              required
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month", "day"]}
                  label="Conducted On"
                  format="YYYY-MM-DD"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setValue(
                      "ConductedOn",
                      dayjs(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      error: !!(errors as any)?.ConductedOn,
                    },
                  }}
                  slots={{
                    openPickerIcon: IconDate,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option?.Name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors?.Batch?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              sx={{ margin: 0 }}
              {...register("engagementtype", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.engagementtype}
              helperText={(errors as any)?.engagementtype?.message}
              name="engagementtype"
              fullWidth
              select
              label="Engagement Type"
              value={engagementType}
              required
              onChange={(e) => setEngagementType(e.target.value)}
              SelectProps={{
                IconComponent: IconDownArrow2,
              }}
            >
              {engagementTypes.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register("EngagementPercentage", {
                required: "This field is required",
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "Percentage cannot be negative",
                },
                max: {
                  value: 100,
                  message: "Percentage cannot exceed 100",
                },
                
              })}
              error={!!(errors as any)?.EngagementPercentage}
              helperText={(errors as any)?.EngagementPercentage?.message}
              fullWidth
              label="Engagement Percentage"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                  step: "0.1", // This allows decimal values with one decimal place
                },
              }}
              onWheel={blurTarget}
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 180 }}
                disabled={
                  !date ||
                  !selectedBatch ||
                  !engagementType ||
                  !watch("EngagementPercentage")
                }
              >
                Save Metrics
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EngagementMetricsForChildren;
