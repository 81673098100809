import { useState } from "react";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdate } from "@refinedev/core";

import PageHeading from "../../components/pageHeading";
import TextFieldShow from "../../components/textFieldShow";
import { IconDownArrow } from "../../icons";

type IUser = {
  email: string;
  id: string;
};

export const MigrationEdit = () => {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();
  const params = useParams();
  const id = params.id;
  const { mutate } = useUpdate();

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: migration } = useList({
    resource: "migrations",
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "id",
        operator: "eq",
        value: id,
      },
    ],
  });

  const renderTitle = () => {
    if (migration?.data[0]?.Status === "Created") {
      if (migration?.data[0]?.CreatedBy?.email === user?.email) {
        return "Cancel Migration";
      } else {
        return "Accept Migration";
      }
    } else {
      return "";
    }
  };

  return (
    <Box
      sx={{
        pb: 5,
      }}
    >
      <PageHeading heading="Migration Details" />
      {migration?.data !== undefined &&
        (migration?.data.length > 0 ? (
          <Box>
            <PageHeading heading={renderTitle()} />
            <Grid container spacing={2}>
              <TextFieldShow
                label="Student Name"
                value={migration?.data[0]?.Student?.ChildName}
              />
              <TextFieldShow
                label="Current Status"
                value={migration?.data[0]?.Status}
              />
              <TextFieldShow
                label="Source Location"
                value={migration?.data[0]?.SourceLocation}
              />
              <TextFieldShow
                label="Migration Location"
                value={migration?.data[0]?.MigrationLocation}
              />
              <TextFieldShow
                label="Created By"
                value={migration?.data[0]?.CreatedBy?.username}
              />
              {migration?.data[0]?.Status === "Accepted" && (
                <TextFieldShow
                  label="Accepted By"
                  value={migration?.data[0]?.AcceptedBy?.username}
                />
              )}
              {migration?.data[0]?.Status === "Others Accepted" && (
                <TextFieldShow
                  label="Accepted By (External Organisation)"
                  value={migration?.data[0]?.ExternalOrganisation?.Name}
                />
              )}
              {migration?.data[0]?.Status === "Outside LIFI Accepted" && (
                <TextFieldShow
                  label="Accepted By (Partner Organisation)"
                  value={migration?.data[0]?.PartnerOrganization?.Name}
                />
              )}
              {migration?.data[0]?.MigrationType && (
                <TextFieldShow
                  label="Migration Type"
                  value={migration?.data[0]?.MigrationType}
                />
              )}
              {migration?.data[0]?.Status === "Created" &&
                migration?.data[0]?.CreatedBy.email !== user?.email && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      Select Student Batch
                    </Typography>
                    <Autocomplete
                      value={selectedBatch}
                      onChange={(event, newValue) => {
                        setSelectedBatch(newValue);
                      }}
                      popupIcon={<IconDownArrow />}
                      id="select-batch"
                      options={dataBatch?.data || []}
                      getOptionLabel={(option) => option?.Name}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={uuidv4()}>
                            {option?.Name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Student Batch"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
              <Grid item xs={12} justifyContent="center">
                {migration?.data[0]?.Status === "Created" &&
                  (migration?.data[0]?.CreatedBy.email === user?.email ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (
                          migration?.data[0]?.CreatedBy.email === user?.email
                        ) {
                          mutate({
                            resource: "migrations",
                            values: {
                              Status: "Cancelled",
                            },
                            id: id,
                            successNotification: () => {
                              return {
                                message: "Migration Cancelled",
                                type: "success",
                              };
                            },
                          });
                        }
                      }}
                    >
                      Cancel Migration
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (
                          migration?.data[0]?.CreatedBy.email !== user?.email
                        ) {
                          if (selectedBatch) {
                            mutate({
                              id: migration?.data[0]?.Student?.id,
                              resource: "students",
                              values: {
                                Batch: selectedBatch?.id,
                              },
                              successNotification: () => {
                                return {
                                  message: "Student Batch Updated",
                                  type: "success",
                                };
                              },
                            });
                            mutate({
                              id: id,
                              resource: "migrations",
                              values: {
                                Status: "Accepted",
                                AcceptedBy: user?.id,
                              },
                              successNotification: () => {
                                return {
                                  message: "Migration Accepted",
                                  type: "success",
                                };
                              },
                            });
                          } else {
                            open({
                              message: "Please select a batch",
                              type: "error",
                            });
                          }
                        }
                      }}
                    >
                      Accept Migration
                    </Button>
                  ))}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <PageHeading heading="No Migration Found" />
        ))}
    </Box>
  );
};
