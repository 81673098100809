import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CYRCSTEMComputers = () => {
  return (
    <Box>

      <DailyActivities
        title="CYRC (STEM Computers)"
        items={menuSections.CYRCSTEMComputers}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CYRCSTEMComputers;

export { CYRCSTEMComputersChildrenAskingQuestionsDuringActivity } from "./children-asking-questions-during-activity";
export { CYRCSTEMComputersChildrenEnrolledInCYRCs } from "./children-enrolled-in-cyrcs";
export { CYRCSTEMComputersChildrenGraduatedGradeFiveToCYRC } from "./children-graduated-grade-5-to-cyrc";
export { CYRCSTEMComputersChildrenParticipatingInGroupActivity } from "./children-participating-in-group-activity";
export { CYRCSTEMComputersScratchClassesProjectsUndertaken } from "./scratch-classes-projects-undertaken";
export { CYRCSTEMComputersSTEMClassesExecuted } from "./stem-classes-executed";
export { CYRCSTEMComputersSTEMClassesPlanned } from "./stem-classes-planned";
export { CYRCSTEMComputersStudentsTestedInBaselineMidlineEndline } from "./students-tested-in-baseline-midline-endline";
export { CYRCSTEMComputersTrainingSessionsConducted } from "./training-sessions-conducted";

