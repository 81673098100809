import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";
import { Breadcrumb } from "@refinedev/mui";
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  TableContainer,
  Table,
  TableBody,
  Paper,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";

import PageHeading from "../../components/pageHeading";
import { TableHeader } from "../../components/tableHeader";
import AttendanceCell from "../../components/attendanceCell";
import { IconDate, IconDownArrow, IconDownArrow2 } from "../../icons";
import { API_URL, TOKEN_KEY } from "../../constants";

// Type definitions
interface IUser {
  email: string;
}

interface ClassType {
  id: number;
  value: string;
  label: string;
}

const CLASS_TYPES: ClassType[] = [
  { id: 1, value: "StorytellingSession", label: "Story telling session" },
  { id: 2, value: "ReadAloudSession", label: "Read aloud session" },
  { id: 3, value: "GroupReadingSession", label: "Group reading session" },
  { id: 4, value: "SilentReadingSession", label: "Silent reading session" },
  { id: 5, value: "WritingClassSession", label: "Writing session" },
  { id: 6, value: "SELSession", label: "SEL session" },
];

export const SchoolLibraryClassConducted: React.FC = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<any>(null);
  const [sessionType, setSessionType] = useState<string>("");
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  // Fetch batches
  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  // Fetch students based on selected batch
  const { data: dataStudents } = useList({
    resource: "students",
    pagination: { pageSize: 100 },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  // Prepare attendance data
  const attendance = dataStudents?.data.map((row) => ({
    Student: row?.id,
    Batch: selectedBatch?.id,
    Date: dayjs(date).format("YYYY-MM-DD"),
    Present: false,
    TypeOfSession: sessionType,
    AttendanceFor: "SchoolLibrary",
  }));

  // Handle form submission
  const onSubmit = async () => {
    try {
      const attendanceData = attendance;
      await axios.post(
        `${API_URL}/api/student-attendance/bulkadd`,
        attendanceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );

      open?.({
        type: "success",
        message: "Attendance Saved successfully",
      });

      // Reset form
      setSelectedBatch(null);
      setDate(null);
      setSessionType("");
      reset();
    } catch (error) {
      open?.({
        type: "error",
        message: "Failed to save assessment",
      });
    }
  };

  // Render date picker
  const renderDatePicker = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Conducted On"
        format="YYYY-MM-DD"
        value={date}
        onChange={setDate}
        slotProps={{
          textField: {
            required: true,
            error: !!(errors as any)?.ConductedOn,
          },
        }}
        slots={{
          openPickerIcon: IconDate,
        }}
      />
    </LocalizationProvider>
  );

  // Render batch selector
  const renderBatchSelector = () => (
    <Controller
      name="Batch"
      control={control}
      rules={{ required: "This field is required" }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          onChange={(_, value) => {
            setSelectedBatch(value);
            field.onChange(value?.id);
          }}
          value={selectedBatch}
          options={dataBatch?.data || []}
          getOptionLabel={(option) => option?.Name}
          popupIcon={<IconDownArrow />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Student Batch"
              variant="outlined"
              error={!!errors.category?.message}
              required
            />
          )}
        />
      )}
    />
  );

  const showTable = selectedBatch && dataStudents?.data.length > 0;
  const showSubmitButton = selectedBatch && date && sessionType;

  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));",
        }}
      >
        <Breadcrumb />
        <PageHeading heading="Session Conducted" />

        <Box
          component="form"
          sx={{ width: "100%", padding: "1rem;" }}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                {renderDatePicker()}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl error={!!(errors as any)?.Batch} fullWidth>
                {renderBatchSelector()}
                <FormHelperText>
                  {(errors as any)?.Batch?.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                required
                label="Session Type"
                value={sessionType}
                onChange={(e) => setSessionType(e.target.value)}
                error={!!(errors as any)?.sessiontype}
                helperText={(errors as any)?.sessiontype?.message}
                SelectProps={{ IconComponent: IconDownArrow2 }}
              >
                {CLASS_TYPES.map(({ id, value, label }) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {showTable && (
            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table aria-label="attendance table" size="small">
                <TableHeader headers={["Student Name", "Status"]} />
                <TableBody>
                  {dataStudents.data.map((row) => (
                    <AttendanceCell
                      key={row?.id}
                      row={row}
                      attendance={attendance}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {showSubmitButton && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 180 }}
              >
                Save Assessment
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SchoolLibraryClassConducted;
