import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type T = {
  slug: string;
  heading: string;
  icon: any;
};

const DailyActivities = ({
  items,
  title,
  borderbottom,
  defaultExpanded,
}: {
  items: Array<T>;
  title: string;
  borderbottom: boolean;
  defaultExpanded?: boolean;
}) => {
  return (
    <Accordion
      variant="outlined"
      elevation={0}
      defaultExpanded={defaultExpanded}
      sx={{
        mt: 2,
        boxShadow: 0,
        mb: 3,
        pb: 1,
        border: 0,
        borderRadius: 0,
        borderBottom: borderbottom ? "1px #aaa solid" : "1px transparent solid",
        backgroundColor: "transparent", // Keep transparent to work with dark/light modes
        "&::before": {
          display: "none", // Removes default hover/active effects
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
              borderRadius: "25px",
              width: "32px",
              height: "32px",
              borderColor: "text.primary",
              color: "text.primary",
            }}
          >
            <ExpandMoreIcon />
          </Box>
        }
        sx={{
          backgroundColor: "transparent", // Ensure it inherits your dark/light mode colors
          "& .MuiAccordionSummary-content": {},
        }}
      >
        <Typography
          sx={{ fontSize: 20, mb: 1, mt: 1, fontWeight: "bold" }}
          gutterBottom
          color="text.primary"
        >
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0 }}>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={6}
          sx={{
            "& > .MuiGrid-item": {
              paddingBottom: "24px", // Add equal bottom padding
            },
          }}
        >
          {items.map((item: T) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              key={uuidv4()}
              sx={{
                paddingTop: "24px", // Ensures consistency in case it's overridden elsewhere
              }}
            >
              <Link
                to={item.slug}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 4,
                    px: 1,
                    py: 1,
                    height: "8rem",
                    backgroundColor: "#f8b86499",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {item.icon}
                  <Typography
                    fontSize={16}
                    variant="body2"
                    fontWeight="bold"
                    color="text.primary"
                    sx={{ textAlign: "center" }}
                  >
                    {item.heading}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DailyActivities;
