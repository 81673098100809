import { useContext, useState } from "react";
import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Box } from "@mui/system";

import {
  AuthPage,
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayout,
  ThemedLayoutV2,
} from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { DataProvider } from "@refinedev/strapi-v4";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import {
  IconAddAttendance,
  IconAddStudent,
  IconAddSurvey,
  IconReport,
  IconCreate,
  IconDashboard,
  IconParentMeet,
  IconStudentActivity,
  IconStudentAssesment,
  IconStudentAttendance,
  IconStudentHealth,
  IconStudent,
  IconStudentsList,
  IconViewLesson,
} from "./icons";

import {
  CreateStudent,
  CreateSurvay,
  StudentList,
  StudentShow,
} from "./pages/students";
import { authProvider, axiosInstance } from "./authProvider";
import { Header } from "./components/header";
import { Title } from "./components/title";
import { API_URL, MIGRATION_FEATURE } from "./constants";
import { ColorModeContext, ColorModeContextProvider } from "./contexts";
import HomePage from "./pages/home";
import { StudentActivityCreate } from "./pages/student-activities";
import { LessonShow } from "./pages/lessons";
import { ParentMeetCreate } from "./pages/parent-meet";
import { StudentHealthCreate } from "./pages/student-health";
import {
  StudentAttendanceCreate,
  StudentAttendanceShow,
} from "./pages/student-attendance";
import {
  StudentAssessmentCreate,
  StudentAssessmentShow,
} from "./pages/student-assessment";
import {
  MigrationCreate,
  MigrationEdit,
  MigrationListAll,
  MigrationListRelevent,
  MigrationListYour,
} from "./pages/migration";

import "./styles/global.css";
import SchoolLibrary, {
  EngagementMetricsForChildren,
  NumberOfBooksIssuedToChildrenInTheLibrary,
  VideosPhotosSharedByFellowsInWhatsapp,
  SchoolStaffInvolvementInLibraryActivities,
  SchoolLibraryClassConducted,
} from "./pages/school-library";
import SchoolSMC, {
  AnecdotesOfParentalCommunityInvolvementInSchool,
  IncreaseInSchoolEnrollmentsAttendance,
  IntroductoryCallsWithSMCPlanByFellows,
  NumberOfEmpowermentTrainingsConducted,
  NumberOfSMCMeetingsConducted,
  ParentsAttendingParentTeacherMeetings,
  ParentsAttendingSMCMeetings,
  SMCMemberParticipationInIntroductoryCall,
  SMCProjectsForSchoolImprovementPerOneYear,
  SuggestionsForSchoolImprovementBySMCMembers,
  VideosPhotosSharedByFellowsInWhatsApp,
} from "./pages/school-smc";
import CommunityLeaderAspectTrainingAspect, {
  ClassroomObservationsByLeaders,
  CommunityLeaderVisitingFellowFourTimesMonth,
  CommunityVideosPhotosSharedByFellowsInWhatsApp,
  FellowsAttendingWeeklyTraining,
  FellowsFillingGoogleFormPostTraining,
  FellowsImplementingSkillsFromOnlineTraining,
  FellowTeacherMeetingsToTrackAttendance,
  NoOfFeedbackSessionsPlannedByLeaders,
  NumberOfKURPICDCallsByLeaders,
  ReportsCreatedByLeadersForMEEvents,
  TrainingsForFellowsInTheMonth,
  VisitsToGovtOfficesSchoolsByMeenu,
} from "./pages/community-leader-aspect-training-aspect";
import CYRCOverall, {
  ActivityBasedClassesConductedInCYRC,
  AverageAttendanceInLearningCenterSchool,
  ChildrenAttendingClasses,
  CYRCOverallVideosPhotosSharedByFellowsInWhatsApp,
} from "./pages/cyrc-overall";
import CYRCFLN, {
  ChildrenCompletingEverydayWorksheets,
  ChildrenParticipatingInGroupActivity,
  CYRCFLNChildrenAskingQuestionsDuringActivity,
  SELSessionsConductedPerBatch,
  StudentsScoringInAssessments,
  StudentsTestedForAssessments,
} from "./pages/cyrc-fln";
import CYRCLibrary, {
  CYRCLibraryAverageAttendanceInLibraryClasses,
  CYRCLibraryBooksIssuedToChildren,
  CYRCLibraryChildrenAskingQuestionsDuringActivity,
  CYRCLibraryChildrenAttendingLibraryClasses,
  CYRCLibraryChildrenParticipatingInGroupActivity,
  CYRCLibraryGroupReadingSessionsConducted,
  CYRCLibraryReadAloudSessionsConducted,
  CYRCLibrarySilentReadingSessionsConducted,
  CYRCLibraryStorytellingSessionsConducted,
  CYRCLibraryWritingClassesConducted,
} from "./pages/cyrc-library";
import CYRCSTEMComputers, {
  CYRCSTEMComputersChildrenAskingQuestionsDuringActivity,
  CYRCSTEMComputersChildrenEnrolledInCYRCs,
  CYRCSTEMComputersChildrenGraduatedGradeFiveToCYRC,
  CYRCSTEMComputersChildrenParticipatingInGroupActivity,
  CYRCSTEMComputersScratchClassesProjectsUndertaken,
  CYRCSTEMComputersSTEMClassesExecuted,
  CYRCSTEMComputersSTEMClassesPlanned,
  CYRCSTEMComputersStudentsTestedInBaselineMidlineEndline,
  CYRCSTEMComputersTrainingSessionsConducted,
} from "./pages/cyrc-stem-computers";
import CYRCManagementCommittee, {
  ChildrenRetainedInCYRCs,
  CYRCManageCommitteeMeetingsConducted,
  CYRCManageEmpowermentTrainingsConducted,
  CYRCManageParentalCommunityInvolvementInCYRCs,
  CYRCManageParentsParticipatingInCommitteeMeetings,
  IncreaseInCYRCEnrollments,
  ParentsAttendingPTMsAndCYRCEvents,
  ProjectsByCommitteeForCYRCimprovement,
  SuggestionsForCYRCImprovementByCommittee,
} from "./pages/cyrc-management-committee";
import CommunityMobilization, {
  CommunityLeaderEventPlansConductionReports,
  CommunityStakeholdersInvolvementAndParticipationAnecdotes,
  HouseVisitsMadeToEachStudentMonthly,
  IrregularRegularChildrenResumingAfterCheckInVisits,
  OneOnOnePhoneCallsWithParentsForAbsentChildren,
  ParentsRespondingPositivelyDuringHouseVisits,
  YouthLeadersEventPlansConductionReports,
} from "./pages/community-mobilization";
import MaatiPathshalaFLN, {
  ChildrenCompletingDailyWorksheets,
  ChildrenParticipatingInGroupActivities,
  MaatiChildrenAskingQuestionsDuringActivity,
  MaatiSELSessionsConductedPerBatch,
  StudentsScoringAboveInAssessments,
  StudentsTestedForAssessmentsBaselineMidlineEndline,
} from "./pages/maati-pathshala-fln";

function App() {
  const [showPassword, setShowPassword] = useState(false);
  const { mode } = useContext(ColorModeContext);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider title="Lifi">
            <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              resources={[
                {
                  name: "dashboard",
                  list: "/",
                  meta: {
                    icon: <IconDashboard />,
                    label: "Dashboard",
                  },
                },
                {
                  name: "students-families",
                  meta: {
                    label: "Student",
                    icon: <IconStudent />,
                  },
                },
                {
                  name: "surveys",
                  list: "/students/add-survey",
                  identifier: "surveys",
                  meta: {
                    icon: <IconAddSurvey />,
                    parent: "students-families",
                    label: "Add Survey",
                  },
                },
                {
                  name: "students",
                  identifier: "students",
                  list: "/students/add-student",
                  meta: {
                    parent: "students-families",
                    icon: <IconAddStudent />,
                    label: "Add Student",
                  },
                },
                {
                  name: "students-list",
                  list: "/students",
                  show: "/students/show/:id",
                  meta: {
                    parent: "students-families",
                    icon: <IconStudentsList />,
                    label: "Your Students",
                  },
                },
                {
                  name: "lessons-show",
                  list: "/lessons",
                  meta: {
                    icon: <IconViewLesson />,
                    label: "View Lesson",
                  },
                },
                {
                  name: "student-assessments",
                  meta: {
                    label: "Student Assessments",
                    icon: <IconStudentAssesment />,
                  },
                },
                {
                  name: "student-assessment-create",
                  list: "/student-assessment",
                  meta: {
                    label: "Enter Assessment",
                    parent: "student-assessments",
                    icon: <IconCreate />,
                  },
                },
                {
                  name: "student-assessments-report",
                  list: "/student-assessment/report",
                  meta: {
                    label: "Assessment Report",
                    parent: "student-assessments",
                    icon: <IconReport />,
                  },
                },
                {
                  name: "student-attendance",
                  meta: {
                    label: "Student Attendance",
                    icon: <IconStudentAttendance />,
                  },
                },
                {
                  name: "student-attendances-create",
                  list: "/student-attendance",
                  meta: {
                    label: "Enter Attendance",
                    parent: "student-attendance",
                    icon: <IconAddAttendance />,
                  },
                },
                {
                  name: "student-attendance-report",
                  list: "/student-attendance/report",
                  meta: {
                    label: "Attendance Report",
                    parent: "student-attendance",
                    icon: <IconReport />,
                  },
                },
                {
                  name: "student-activity",
                  meta: {
                    icon: <IconStudentActivity />,
                    label: "Student Activity",
                  },
                },
                {
                  name: "student-activities",
                  identifier: "/student-activities",
                  list: "/student-activity",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create Activity",
                    parent: "student-activity",
                  },
                },
                {
                  name: "student-health",
                  meta: {
                    icon: <IconStudentHealth />,
                    label: "Student Health",
                  },
                },
                {
                  name: "student-healths",
                  identifier: "/student-healths",
                  list: "/student-health",
                  create: "/student-health",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create",
                    parent: "student-health",
                  },
                },
                {
                  name: "parent-meet",
                  meta: {
                    icon: <IconParentMeet />,
                    label: "Parent Meet",
                  },
                },
                {
                  name: "parent-meets",
                  identifier: "/parent-meets",
                  list: "/parent-meet",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create",
                    parent: "parent-meet",
                  },
                },
                {
                  name: "migration",
                  meta: {
                    icon: <IconStudent />,
                    label: "Migrations",
                  },
                },
                {
                  name: "migrations",
                  identifier: "/migration/new",
                  list: "/migration/new",
                  meta: {
                    icon: <IconCreate />,
                    label: "New",
                    parent: "migration",
                  },
                },
                {
                  name: "migrations",
                  identifier: "/migration",
                  list: "/migration",
                  edit: "/migration/edit/:id",
                  meta: {
                    icon: <IconStudentAssesment />,
                    label: "All",
                    parent: "migration",
                  },
                },
                {
                  name: "migrations",
                  identifier: "/migration/your",
                  list: "/migration/your",
                  meta: {
                    icon: <IconStudentAssesment />,
                    label: "Your",
                    parent: "migration",
                  },
                },
                {
                  name: "migrations",
                  identifier: "/migration/relevent",
                  list: "/migration/relevent",
                  meta: {
                    icon: <IconStudentAssesment />,
                    label: "Relevent",
                    parent: "migration",
                  },
                },
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
              Title={Title}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayout Header={Header}>
                        <Outlet />
                      </ThemedLayout>
                    </Authenticated>
                  }
                >
                  <Route index element={<HomePage />} />
                  <Route path="/students">
                    <Route index element={<StudentList />} />
                    <Route path="show/:id" element={<StudentShow />} />
                    <Route path="add-student" element={<CreateStudent />} />
                    <Route path="add-survey" element={<CreateSurvay />} />
                  </Route>
                  <Route path="/student-activity">
                    <Route index element={<StudentActivityCreate />} />
                  </Route>
                  <Route path="/lessons">
                    <Route index element={<LessonShow />} />
                  </Route>
                  <Route path="/student-attendance">
                    <Route index element={<StudentAttendanceCreate />} />
                    <Route path="report" element={<StudentAttendanceShow />} />
                  </Route>
                  <Route path="/student-assessment">
                    <Route index element={<StudentAssessmentCreate />} />
                    <Route path="report" element={<StudentAssessmentShow />} />
                  </Route>
                  <Route path="/student-health">
                    <Route index element={<StudentHealthCreate />} />
                  </Route>
                  <Route path="/parent-meet">
                    <Route index element={<ParentMeetCreate />} />
                  </Route>
                  {MIGRATION_FEATURE && (
                    <Route path="/migration">
                      <Route index element={<MigrationListAll />} />
                      <Route path="new" element={<MigrationCreate />} />
                      <Route path="your" element={<MigrationListYour />} />
                      <Route
                        path="relevent"
                        element={<MigrationListRelevent />}
                      />
                      <Route path="edit/:id" element={<MigrationEdit />} />
                    </Route>
                  )}
                  <Route path="/school-library">
                    <Route index element={<SchoolLibrary />} />
                    <Route
                      path="class-conducted"
                      element={<SchoolLibraryClassConducted />}
                    />
                    <Route
                      path="engagement-metrics-for-children"
                      element={<EngagementMetricsForChildren />}
                    />
                    <Route
                      path="number-of-books-issued-to-children-in-the-library"
                      element={<NumberOfBooksIssuedToChildrenInTheLibrary />}
                    />
                    <Route
                      path="videos-photos-shared-by-fellows-in-whatsapp"
                      element={<VideosPhotosSharedByFellowsInWhatsapp />}
                    />
                    <Route
                      path="school-staff-involvement-in-library-activities"
                      element={<SchoolStaffInvolvementInLibraryActivities />}
                    />
                  </Route>
                  <Route path="/school-smc">
                    <Route index element={<SchoolSMC />} />
                    <Route
                      path="introductory-calls-with-smc-plan-by-fellows"
                      element={<IntroductoryCallsWithSMCPlanByFellows />}
                    />
                    <Route
                      path="anecdotes-of-parental-community-involvement-in-school"
                      element={
                        <AnecdotesOfParentalCommunityInvolvementInSchool />
                      }
                    />
                    <Route
                      path="increase-in-school-enrollments-attendance"
                      element={<IncreaseInSchoolEnrollmentsAttendance />}
                    />
                    <Route
                      path="number-of-empowerment-trainings-conducted"
                      element={<NumberOfEmpowermentTrainingsConducted />}
                    />
                    <Route
                      path="number-of-smc-meetings-conducted"
                      element={<NumberOfSMCMeetingsConducted />}
                    />
                    <Route
                      path="parents-attending-parent-teacher-meetings"
                      element={<ParentsAttendingParentTeacherMeetings />}
                    />
                    <Route
                      path="parents-attending-smc-meetings"
                      element={<ParentsAttendingSMCMeetings />}
                    />
                    <Route
                      path="smc-member-participation-in-introductory-call"
                      element={<SMCMemberParticipationInIntroductoryCall />}
                    />
                    <Route
                      path="smc-projects-for-school-improvement-per-1-year"
                      element={<SMCProjectsForSchoolImprovementPerOneYear />}
                    />
                    <Route
                      path="suggestions-for-school-improvement-by-smc-members"
                      element={<SuggestionsForSchoolImprovementBySMCMembers />}
                    />
                    <Route
                      path="videos-photos-shared-by-fellows-in-whatsapp"
                      element={<VideosPhotosSharedByFellowsInWhatsApp />}
                    />
                  </Route>
                  <Route path="/community-leader-aspect-training-aspect">
                    <Route
                      index
                      element={<CommunityLeaderAspectTrainingAspect />}
                    />
                    <Route
                      path="classroom-observations-by-leaders"
                      element={<ClassroomObservationsByLeaders />}
                    />
                    <Route
                      path="community-leader-visiting-fellow-4x-month"
                      element={<CommunityLeaderVisitingFellowFourTimesMonth />}
                    />
                    <Route
                      path="fellow-teacher-meetings-to-track-attendance"
                      element={<FellowTeacherMeetingsToTrackAttendance />}
                    />
                    <Route
                      path="fellows-attending-weekly-training"
                      element={<FellowsAttendingWeeklyTraining />}
                    />
                    <Route
                      path="fellows-filling-google-form-post-training"
                      element={<FellowsFillingGoogleFormPostTraining />}
                    />
                    <Route
                      path="fellows-implementing-skills-from-online-training"
                      element={<FellowsImplementingSkillsFromOnlineTraining />}
                    />
                    <Route
                      path="no-of-feedback-sessions-planned-by-leaders"
                      element={<NoOfFeedbackSessionsPlannedByLeaders />}
                    />
                    <Route
                      path="number-of-kurpicd-calls-by-leaders"
                      element={<NumberOfKURPICDCallsByLeaders />}
                    />
                    <Route
                      path="reports-created-by-leaders-for-m-e-events"
                      element={<ReportsCreatedByLeadersForMEEvents />}
                    />
                    <Route
                      path="trainings-for-fellows-in-the-month"
                      element={<TrainingsForFellowsInTheMonth />}
                    />
                    <Route
                      path="videos-photos-shared-by-fellows-in-whatsapp"
                      element={
                        <CommunityVideosPhotosSharedByFellowsInWhatsApp />
                      }
                    />
                    <Route
                      path="visits-to-govt-offices-schools-by-meenu"
                      element={<VisitsToGovtOfficesSchoolsByMeenu />}
                    />
                  </Route>
                  <Route path="/cyrc-overall">
                    <Route index element={<CYRCOverall />} />
                    <Route
                      path="activity-based-classes-conducted-in-cyrc"
                      element={<ActivityBasedClassesConductedInCYRC />}
                    />
                    <Route
                      path="average-attendance-in-learning-center-school"
                      element={<AverageAttendanceInLearningCenterSchool />}
                    />
                    <Route
                      path="children-attending-classes-80"
                      element={<ChildrenAttendingClasses />}
                    />
                    <Route
                      path="videos-photos-shared-by-fellows-in-whatsapp"
                      element={
                        <CYRCOverallVideosPhotosSharedByFellowsInWhatsApp />
                      }
                    />
                  </Route>
                  <Route path="/cyrc-fln">
                    <Route index element={<CYRCFLN />} />
                    <Route
                      path="children-completing-everyday-worksheets"
                      element={<ChildrenCompletingEverydayWorksheets />}
                    />
                    <Route
                      path="sel-sessions-conducted-per-batch"
                      element={<SELSessionsConductedPerBatch />}
                    />
                    <Route
                      path="children-asking-questions-during-activity"
                      element={<CYRCFLNChildrenAskingQuestionsDuringActivity />}
                    />
                    <Route
                      path="children-participating-in-group-activity"
                      element={<ChildrenParticipatingInGroupActivity />}
                    />
                    <Route
                      path="students-tested-for-assessments"
                      element={<StudentsTestedForAssessments />}
                    />
                    <Route
                      path="students-scoring-70-in-assessments"
                      element={<StudentsScoringInAssessments />}
                    />
                  </Route>
                  <Route path="/cyrc-library">
                    <Route index element={<CYRCLibrary />} />
                    <Route
                      path="average-attendance-in-library-classes"
                      element={<CYRCLibraryAverageAttendanceInLibraryClasses />}
                    />
                    <Route
                      path="books-issued-to-children"
                      element={<CYRCLibraryBooksIssuedToChildren />}
                    />
                    <Route
                      path="children-asking-questions-during-activity"
                      element={
                        <CYRCLibraryChildrenAskingQuestionsDuringActivity />
                      }
                    />
                    <Route
                      path="children-attending-library-classes-80"
                      element={<CYRCLibraryChildrenAttendingLibraryClasses />}
                    />
                    <Route
                      path="children-participating-in-group-activity"
                      element={
                        <CYRCLibraryChildrenParticipatingInGroupActivity />
                      }
                    />
                    <Route
                      path="group-reading-sessions-conducted"
                      element={<CYRCLibraryGroupReadingSessionsConducted />}
                    />
                    <Route
                      path="read-aloud-sessions-conducted"
                      element={<CYRCLibraryReadAloudSessionsConducted />}
                    />
                    <Route
                      path="silent-reading-sessions-conducted"
                      element={<CYRCLibrarySilentReadingSessionsConducted />}
                    />
                    <Route
                      path="storytelling-sessions-conducted"
                      element={<CYRCLibraryStorytellingSessionsConducted />}
                    />
                    <Route
                      path="writing-classes-conducted"
                      element={<CYRCLibraryWritingClassesConducted />}
                    />
                  </Route>
                  <Route path="/cyrc-stem-computers">
                    <Route index element={<CYRCSTEMComputers />} />
                    <Route
                      path="stem-classes-planned"
                      element={<CYRCSTEMComputersSTEMClassesPlanned />}
                    />
                    <Route
                      path="stem-classes-executed"
                      element={<CYRCSTEMComputersSTEMClassesExecuted />}
                    />
                    <Route
                      path="children-enrolled-in-cyrcs"
                      element={<CYRCSTEMComputersChildrenEnrolledInCYRCs />}
                    />
                    <Route
                      path="children-graduated-grade-5-to-cyrc"
                      element={
                        <CYRCSTEMComputersChildrenGraduatedGradeFiveToCYRC />
                      }
                    />
                    <Route
                      path="training-sessions-conducted"
                      element={<CYRCSTEMComputersTrainingSessionsConducted />}
                    />
                    <Route
                      path="students-tested-in-baseline-midline-endline"
                      element={
                        <CYRCSTEMComputersStudentsTestedInBaselineMidlineEndline />
                      }
                    />
                    <Route
                      path="children-asking-questions-during-activity"
                      element={
                        <CYRCSTEMComputersChildrenAskingQuestionsDuringActivity />
                      }
                    />
                    <Route
                      path="children-participating-in-group-activity"
                      element={
                        <CYRCSTEMComputersChildrenParticipatingInGroupActivity />
                      }
                    />
                    <Route
                      path="scratch-classes-projects-undertaken"
                      element={
                        <CYRCSTEMComputersScratchClassesProjectsUndertaken />
                      }
                    />
                  </Route>
                  <Route path="/cyrc-management-committee">
                    <Route index element={<CYRCManagementCommittee />} />
                    <Route
                      path="children-retained-in-cyrcs"
                      element={<ChildrenRetainedInCYRCs />}
                    />
                    <Route
                      path="committee-meetings-conducted"
                      element={<CYRCManageCommitteeMeetingsConducted />}
                    />
                    <Route
                      path="empowerment-trainings-conducted"
                      element={<CYRCManageEmpowermentTrainingsConducted />}
                    />
                    <Route
                      path="increase-in-cyrc-enrollments"
                      element={<IncreaseInCYRCEnrollments />}
                    />
                    <Route
                      path="parental-community-involvement-in-cyrcs"
                      element={
                        <CYRCManageParentalCommunityInvolvementInCYRCs />
                      }
                    />
                    <Route
                      path="parents-attending-ptms-and-cyrc-events"
                      element={<ParentsAttendingPTMsAndCYRCEvents />}
                    />
                    <Route
                      path="parents-participating-in-committee-meetings"
                      element={
                        <CYRCManageParentsParticipatingInCommitteeMeetings />
                      }
                    />
                    <Route
                      path="projects-by-committee-for-cyrc-improvement"
                      element={<ProjectsByCommitteeForCYRCimprovement />}
                    />
                    <Route
                      path="suggestions-for-cyrc-improvement-by-committee"
                      element={<SuggestionsForCYRCImprovementByCommittee />}
                    />
                  </Route>
                  <Route path="/community-mobilization">
                    <Route index element={<CommunityMobilization />} />
                    <Route
                      path="community-leader-s-event-plans-conduction-reports"
                      element={<CommunityLeaderEventPlansConductionReports />}
                    />
                    <Route
                      path="community-stakeholders-involvement-and-participation-anecdotes"
                      element={
                        <CommunityStakeholdersInvolvementAndParticipationAnecdotes />
                      }
                    />
                    <Route
                      path="house-visits-made-to-each-student-monthly"
                      element={<HouseVisitsMadeToEachStudentMonthly />}
                    />
                    <Route
                      path="irregular-regular-children-resuming-after-check-in-visits"
                      element={
                        <IrregularRegularChildrenResumingAfterCheckInVisits />
                      }
                    />
                    <Route
                      path="one-on-one-phone-calls-with-parents-for-100-absent-children"
                      element={
                        <OneOnOnePhoneCallsWithParentsForAbsentChildren />
                      }
                    />
                    <Route
                      path="parents-responding-positively-during-house-visits"
                      element={<ParentsRespondingPositivelyDuringHouseVisits />}
                    />
                    <Route
                      path="youth-leaders-event-plans-conduction-reports"
                      element={<YouthLeadersEventPlansConductionReports />}
                    />
                  </Route>
                  <Route path="/maati-pathshala-fln">
                    <Route index element={<MaatiPathshalaFLN />} />
                    <Route
                      path="children-asking-questions-during-activity"
                      element={<MaatiChildrenAskingQuestionsDuringActivity />}
                    />
                    <Route
                      path="children-completing-daily-worksheets"
                      element={<ChildrenCompletingDailyWorksheets />}
                    />
                    <Route
                      path="children-participating-in-group-activities"
                      element={<ChildrenParticipatingInGroupActivities />}
                    />
                    <Route
                      path="sel-sessions-conducted-per-batch"
                      element={<MaatiSELSessionsConductedPerBatch />}
                    />
                    <Route
                      path="students-scoring-above-70-in-assessments"
                      element={<StudentsScoringAboveInAssessments />}
                    />
                    <Route
                      path="students-tested-for-assessments-baseline-midline-endline"
                      element={
                        <StudentsTestedForAssessmentsBaselineMidlineEndline />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route
                    path="/login"
                    element={
                      <AuthPage
                        type="login"
                        registerLink=""
                        rememberMe={false}
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                              <Box
                                sx={{
                                  position: "absolute",
                                  right: "50px",
                                  top: "277px",
                                }}
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                  const password = document.getElementById(
                                    "password"
                                  ) as HTMLInputElement | null;
                                  showPassword
                                    ? (password.type = "text")
                                    : (password.type = "password");
                                }}
                              >
                                {showPassword ? (
                                  <AiFillEye color="#f8b864" />
                                ) : (
                                  <AiFillEyeInvisible color="#f8b864" />
                                )}
                              </Box>
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <AuthPage
                        type="forgotPassword"
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                  <Route
                    path="/update-password"
                    element={
                      <AuthPage
                        type="updatePassword"
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                </Route>
                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2 Header={Header} Title={Title}>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
