import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const ReportsCreatedByLeadersForMEEvents = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Reports created by leaders for M&E / events" />
    </Box>
  );
};
