import React, { useMemo } from "react";
import { Box } from "@mui/system";

import DailyActivities from "../components/dailyActivities";
import HomeBanner from "../components/homeBanner";
import { menuSections } from "../Data/Cards";

const HomePage: React.FC = () => {
  // Memoize sections to prevent unnecessary re-renders
  const sections = useMemo(
    () => [
      {
        title: "Daily Activities",
        items: menuSections.dailyActivities,
        borderbottom: true,
        defaultExpanded: true,
      },
      {
        title: "Weekly/Monthly Activities",
        items: menuSections.weeklyActivities,
        borderbottom: true,
        defaultExpanded: true,
      },
      {
        title: "Reports",
        items: menuSections.reports,
        borderbottom: true,
        defaultExpanded: true,
      },
      {
        title: "M&E",
        items: menuSections.mAndE,
        borderbottom: false,
        defaultExpanded: true,
      },
    ],
    []
  );

  return (
    <Box>
      <HomeBanner />
      {sections.map((section) => (
        <DailyActivities
          key={section.title}
          title={section.title}
          items={section.items}
          borderbottom={section.borderbottom}
          defaultExpanded={section.defaultExpanded}
        />
      ))}
    </Box>
  );
};

export default HomePage;
