import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const CYRCOverall = () => {
  return (
    <Box>

      <DailyActivities
        title="CYRC (Overall)"
        items={menuSections.cyrcOverall}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default CYRCOverall;

export { ActivityBasedClassesConductedInCYRC } from "./activity-based-classes-conducted-in-cyrc";
export { AverageAttendanceInLearningCenterSchool } from "./average-attendance-in-learning-center-school";
export { ChildrenAttendingClasses } from "./children-attending-classes-80";
export { CYRCOverallVideosPhotosSharedByFellowsInWhatsApp } from "./videos-photos-shared-by-fellows-in-whatsapp";
