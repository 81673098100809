import { Box } from "@mui/system";
import DailyActivities from "../../components/dailyActivities";
import { menuSections } from "../../Data/Cards";

const SchoolLibrary = () => {
  return (
    <Box>
      <DailyActivities
        title="School Library"
        items={menuSections.schoolLibrary}
        borderbottom={false}
        defaultExpanded={true}
      />
    </Box>
  );
};

export default SchoolLibrary;

export { SchoolLibraryClassConducted } from "./class-conducted";
export { EngagementMetricsForChildren } from "./engagement-metrics-for-children";
export { NumberOfBooksIssuedToChildrenInTheLibrary } from "./number-of-books-issued-to-children-in-the-library";
export { VideosPhotosSharedByFellowsInWhatsapp } from "./videos-photos-shared-by-fellows-in-whatsapp";
export { SchoolStaffInvolvementInLibraryActivities } from "./school-staff-involvement-in-library-activities";
