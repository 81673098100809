import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const OneOnOnePhoneCallsWithParentsForAbsentChildren = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="One-on-one phone calls with parents for 100% absent children" />
    </Box>
  );
};
