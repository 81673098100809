import { useState, useEffect } from "react";
import PageHeading from "../../components/pageHeading";
import { useForm } from "@refinedev/react-hook-form";
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  Stack,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconDate, IconDownArrow, IconFileUpload } from "../../icons";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { Breadcrumb } from "@refinedev/mui";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "../../constants";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";

type IUser = {
  email: string;
  id: string;
};

type FileWithPreview = {
  file: File;
  preview: string;
  id?: string;
};

export const VideosPhotosSharedByFellowsInWhatsapp: React.FC = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [date, setDate] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { open } = useNotification();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("files", file);

    const response = await axios.post(`${API_URL}/api/upload`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return response.data[0];
  };

  const onSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);

      // Upload all files first
      const uploadPromises = selectedFiles.map((file) => uploadFile(file.file));
      const uploadedFiles = await Promise.all(uploadPromises);

      const fileIds = uploadedFiles.map((file) => file.id);

      const metricsData = {
        data: {
          Batch: selectedBatch?.id,
          DateShared: dayjs(date).format("YYYY-MM-DD"),
          Description: data.Description,
          SharedBy: user?.id,
          SharedFor: "SchoolLibrary",
          File: fileIds,
        },
      };

      await axios.post(`${API_URL}/api/media-shares`, metricsData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      open?.({
        type: "success",
        message: "Saved successfully",
      });

      // Reset form
      setSelectedBatch(null);
      setDate(null);
      setSelectedFiles([]);
      reset();
    } catch (error) {
      open?.({
        type: "error",
        message: error.response?.data?.error?.message || "Failed to save",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) return;

    const newFiles: FileWithPreview[] = Array.from(files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setSelectedFiles((prev) => [...prev, ...newFiles]);
    setValue("Media", true, { shouldValidate: true });
  };

  // Cleanup previews on unmount
  useEffect(() => {
    return () => {
      selectedFiles.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));",
      }}
    >
      <Breadcrumb />
      <PageHeading heading="Videos Photos Shared By Fellows In Whatsapp" />
      <Box
        component="form"
        sx={{ width: "100%", padding: "1rem" }}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                ".MuiTextField-root": { minWidth: "100% !important" },
                ".MuiStack-root": { marginTop: "-8px !important" },
              }}
              required
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month", "day"]}
                  label="Conducted On"
                  format="YYYY-MM-DD"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setValue(
                      "ConductedOn",
                      dayjs(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      error: !!(errors as any)?.ConductedOn,
                    },
                  }}
                  slots={{
                    openPickerIcon: IconDate,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option?.Name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors?.Batch?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ margin: 0 }}
              {...register("Description")}
              error={!!(errors as any)?.Description}
              helperText={(errors as any)?.Description?.message}
              margin="normal"
              fullWidth
              label="Description (optional)"
              name="Description"
              multiline
              minRows={5}
              maxRows={5}
            />
          </Grid>
          <Stack
            direction="column"
            gap={2}
            flexWrap="wrap"
            sx={{ marginTop: "24px", marginLeft: "24px" }}
          >
            <label htmlFor="images-input">
              <input
                id="images-input"
                type="file"
                style={{ display: "none" }}
                onChange={onChangeHandler}
                multiple
                accept="image/*,video/*"
              />
              <LoadingButton
                loading={isSubmitting}
                loadingPosition="end"
                endIcon={<IconFileUpload />}
                variant="contained"
                component="span"
                disableElevation
                sx={{ textTransform: "none" }}
              >
                Select Media
              </LoadingButton>
              <br />
              {errors.Media && (
                <Typography variant="caption" color="#fa541c">
                  {errors.Media?.message?.toString()}
                </Typography>
              )}
            </label>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {selectedFiles.map((file, index) => (
                <Box sx={{ position: "relative" }} key={uuidv4()}>
                  <Box
                    component="img"
                    sx={{ maxWidth: 250, maxHeight: 250 }}
                    src={file.preview}
                    alt="Selected media"
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                    }}
                    onClick={() => {
                      URL.revokeObjectURL(file.preview);
                      setSelectedFiles(
                        selectedFiles.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Stack>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 180 }}
                disabled={
                  !date ||
                  !selectedBatch ||
                  !selectedFiles.length ||
                  isSubmitting
                }
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
