import { Breadcrumb } from "@refinedev/mui";
import { Box } from "@mui/material";
import PageHeading from "../../components/pageHeading";
export const NoOfFeedbackSessionsPlannedByLeaders = () => {
  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="No. of Feedback sessions planned by leaders" />
    </Box>
  );
};
